// Bibliotecas
import React from 'react';
import styled, { css } from 'styled-components';

// Componentes
import Layout from 'components/layout';
import BreadCrumb from 'components/breadCrumb';
import { device } from 'components/device';
import HealthInsurance from 'components/HealthInsurance';
import { DSButtonContent } from '../../components/CmsComponents/DSButtonContent';

// Styles
const Container = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  padding-top: 1.875rem;

  @media ${device.laptop} {
    display: block;
    padding: 0 20px;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const CustosDosProcedimentos = () => {
  const title =
    'Verifique quais os procedimentos e serviços com cobertura obrigatória pela ANS';
  const description =
    'O Rol da ANS é uma lista de procedimentos e serviços que as operadoras de planos de saúde devem cobrir, conforme a legislação brasileira. Ele garante uma cobertura mínima para os usuários, mas não inclui todos os tratamentos, apenas os considerados essenciais ou mais comuns. É possível verificar a cobertura do seu plano através das ferramentas disponibilizadas pela ANS bem como acompanhar o trâmite das propostas de atualização do Rol. O Rol é periodicamente atualizado a fim de ampliar a cobertura dos planos de saúde, uma vez que novas tecnologias são continuamente incorporadas à prática assistencial.';
  const buttons = [
    {
      title: 'Consulte o procedimento',
      url: 'https://www.ans.gov.br/ROL-web/',
      target: '_blank',
    },
    {
      title: 'Baixe o PDF',
      url:
        'https://www.ans.gov.br/images/stories/Legislacao/rn/1_compressed.pdf',
      target: '_blank',
    },
    {
      title: 'Saiba mais',
      url:
        'https://www.gov.br/ans/pt-br/acesso-a-informacao/participacao-da-sociedade/atualizacao-do-rol-de-procedimentos',
      target: '_blank',
    },
  ];

  return (
    <Layout>
      <Container>
        <Wrapper>
          <BreadCrumb markdown="1. [Home](/) > 2. Coberturas e Planos de Saúde" />
        </Wrapper>
      </Container>
      <DSButtonContent
        title={title}
        description={description}
        buttons={buttons}
      />
      <HealthInsurance defaultUF="SP" />
    </Layout>
  );
};

export default CustosDosProcedimentos;
